import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { Constants } from 'src/app/core/constants/constants';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { optimyRoutes } from './core/constants/optimyRoutes';
import { CobrowseRecordingGuard } from './core/guards/cobrowse-recording/cobrowse-recording.guard';
import { SaveRedirectComponent } from './modules/root/save-redirect/save-redirect.component';

const numberRegex = '[aAcCeEgG]';
const regexMatcher = (url: UrlSegment[]) => {
  return url[0].path.length === 1 && url[0].path.match(numberRegex)
    ? {
        consumed: url,
        posParams: {
          inviteCode: new UrlSegment(url[1].path, {}),
          linkType: new UrlSegment(url[0].path, {})
        }
      }
    : null;
};

const routes: Routes = [
  {
    path: Constants.routes.login,
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: `${Constants.routes.dashboard}`,
    loadChildren: () =>
      import('src/app/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      )
  },
  {
    path: Constants.routes.requestAppointment,
    loadChildren: () =>
      import('src/app/schedule-appointment/schedule-appointment.module').then(
        (m) => m.ScheduleAppointmentModule
      )
  },
  {
    path: Constants.routes.liveChatSession,
    loadChildren: () =>
      import('src/app/book-live-chat/book-live-chat.module').then(
        (m) => m.BookLiveChatModule
      )
  },
  {
    path: 'chat/:chatId',
    loadChildren: () =>
      import('./modules/external-chat-outlet/external-chat-outlet.module').then(
        (m) => m.ExternalChatOutletModule
      )
  },
  {
    path: Constants.routes.empty,
    redirectTo: `${Constants.routes.dashboard}/${optimyRoutes.tempOutlets.queue}/${optimyRoutes.tempOutlets.board}`,
    pathMatch: 'full'
  },
  {
    path: `${Constants.routes.dashboard}/${Constants.routes.save}/${optimyRoutes.tempOutlets.queue}`,
    redirectTo: `${Constants.routes.dashboard}/${optimyRoutes.tempOutlets.queue}`,
    pathMatch: 'full'
  },
  {
    // this code is to make the old links to still works.
    path: `${Constants.routes.save}/${Constants.routes.saveRedirect}`,
    component: SaveRedirectComponent,
    pathMatch: 'full'
  },
  {
    matcher: regexMatcher,
    component: SaveRedirectComponent
  },
  {
    path: Constants.routes.wildCard,
    redirectTo: '404'
  },
  {
    path: '404',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
